import { useEffect, useRef } from 'react';
import lozad from 'lozad';
import cls from 'classnames';
import Container from '@/components/common/Container';
import { STATIC_ASSETS_URL } from '@/constants/common';
import styles from './Video.module.scss';

const Video: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const observer = lozad(videoRef.current, {
      load: async function (video: HTMLVideoElement) {
        for (const source in video.children) {
          const videoSource = video.children[source] as HTMLSourceElement;
          if (
            typeof videoSource.tagName === 'string' &&
            videoSource.tagName === 'SOURCE'
          ) {
            videoSource.src = `${STATIC_ASSETS_URL}${videoSource.dataset.src}`;
            delete videoSource.dataset.src;
          }
        }
        video.classList.remove('lozad');
        video.load();
      },
    });

    observer.observe();
  }, []);

  return (
    <div className={styles.container}>
      <Container>
        <video
          ref={videoRef}
          className={cls(styles.video__container, 'lozad')}
          muted
          loop
          controls
          autoPlay
        >
          <source
            data-src="/overseacdn/homepage/static/videos/hero.mp4"
            type="video/mp4"
          />
        </video>
      </Container>
    </div>
  );
};

export default Video;
