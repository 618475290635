import { useCallback, useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence, useInView } from 'framer-motion';
import type { Variants } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import cls from 'classnames';
import {
  formatHighlight,
  formatNewLine,
  formatQuote,
} from '@/components/common/Intl';
import Container from '@/components/common/Container';
import { simpleThrottle } from '@/utils';
import { STATIC_ASSETS_URL } from '@/constants/common';
import styles from './Strengths.module.scss';

enum Directions {
  PRE = -1,
  NEXT = 1,
}

const variants: Variants = {
  enter: (direction: Directions) => {
    return {
      x: direction === Directions.PRE ? 10 : -10,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: Directions) => {
    return {
      zIndex: 0,
      x: direction === Directions.NEXT ? 10 : -10,
      opacity: 0,
    };
  },
};

const highlightImgVariants: Variants = {
  enter: {
    zIndex: 0,
    opacity: 0,
  },
  center: {
    zIndex: 1,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    opacity: 0.5,
  },
};

const autoPlayInterval = 6000; /** ms */

const Strengths: React.FC = () => {
  const [[page, direction], setPage] = useState<[number, number]>([0, 0]);
  const autoPlayTimer = useRef<number>();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerIsInView = useInView(containerRef, { once: true });

  useEffect(() => {
    if (containerIsInView) {
      const autoPlay = () => {
        autoPlayTimer.current && window.clearTimeout(autoPlayTimer.current);
        autoPlayTimer.current = window.setTimeout(() => {
          handlePaginate(page, 1);
        }, autoPlayInterval);
      };

      autoPlay();
    }

    return () => {
      autoPlayTimer.current && window.clearTimeout(autoPlayTimer.current);
    };
  }, [page, containerIsInView]);

  const handlePaginate = (page: number, newDirection: Directions) => {
    let newPage = page + newDirection;

    if (newDirection === Directions.PRE && newPage < 0) {
      newPage = 4;
    }

    if (newDirection === Directions.NEXT && newPage > 4) {
      newPage = 0;
    }
    setPage([newPage, newDirection]);
  };

  /* eslint-disable */
  const throttlePaginateCb = useCallback(simpleThrottle(handlePaginate), []);
  /* eslint-enable */
  return (
    <section ref={containerRef} className={styles.container}>
      <Container className={styles.wrapper} data-title="strengths">
        <div className={cls(styles.title, 'font')}>
          <FormattedMessage
            id="home.strengths.title"
            defaultMessage="The <h>five core</h> competitiveness of youdao Ads"
            values={{
              h: formatHighlight,
              n: formatNewLine,
            }}
          />
        </div>

        <AnimatePresence exitBeforeEnter custom={direction}>
          <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: 0.3 }}
            className={styles.highlight}
          >
            <div className={styles.highlight__title}>
              <FormattedMessage
                id={`home.strengths.highlight.${page}.title`}
                defaultMessage="title"
              />
            </div>

            <div className={styles.highlight__content}>
              {
                <FormattedMessage
                  id={`home.strengths.highlight.${page}.content`}
                  defaultMessage="content"
                  values={{
                    q: formatQuote,
                  }}
                />
              }
            </div>
          </motion.div>
        </AnimatePresence>

        {containerIsInView ? (
          <AnimatePresence>
            <motion.div
              key={page}
              variants={highlightImgVariants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{ duration: 0.6 }}
              className={styles.highlight__img}
              data-index={`0${page + 1}`}
            >
              <img
                src={`${STATIC_ASSETS_URL}/overseacdn/homepage/static/images/home/strengths/highlight-${page}.png`}
                width="655"
                height="690"
                alt="highlight-image"
              />
            </motion.div>
          </AnimatePresence>
        ) : null}

        <div className={styles.actions}>
          <button
            className={styles.pre}
            onClick={() => throttlePaginateCb(page, Directions.PRE)}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                r="23.5"
                transform="matrix(-1 0 0 1 24 24)"
                stroke="currentColor"
              />
              <path
                d="M27.0338 18.0338L21.0676 24L27.0338 29.9662"
                stroke="currentColor"
                strokeWidth="2"
              />
            </svg>
          </button>
          <button
            className={styles.next}
            onClick={() => throttlePaginateCb(page, Directions.NEXT)}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                r="23.5"
                transform="matrix(1 0 0 -1 24 24)"
                stroke="currentColor"
              />
              <path
                d="M20.9662 29.9662L26.9324 24L20.9662 18.0338"
                stroke="currentColor"
                strokeWidth="2"
              />
            </svg>
          </button>
        </div>
      </Container>
    </section>
  );
};

export default Strengths;
