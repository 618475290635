import { FormattedMessage, useIntl } from 'react-intl';
import AutoIncNumber from '@/components/common/AutoIncNumber';
import { formatNewLine } from '@/components/common/Intl';
import styles from './Resources.module.scss';
import LazyImg from '@/components/common/LazyImg';
import cls from 'classnames';
import lozad from 'lozad';
import { STATIC_ASSETS_URL } from '@/constants/common';
import { useEffect } from 'react';

const hightlights = [
  {
    value: 1000,
    unit: '万',
    content: '全球KOL/明星合作资源',
  },
  {
    value: 75,
    unit: '',
    content: '覆盖国家/地区数量',
  },
  {
    value: 20,
    unit: '万',
    content: '直接触达用户',
  },
  {
    value: 13,
    unit: '万',
    content: '连接全球主流媒体',
  },
  {
    value: 2000,
    unit: '',
    content: '覆盖游戏媒体/论坛',
  },
  {
    value: 1,
    unit: '万',
    content: '户外/地标广告资源',
  },
];

const lazyloadBgClassName = 'lazyloadBg';

const Resources: React.FC = () => {
  const intl = useIntl();

  useEffect(() => {
    const observer = lozad(`.${styles.content}`, {
      load: function (img: HTMLImageElement) {
        img.style.backgroundImage = `url(${STATIC_ASSETS_URL}${img.dataset.bg})`;
        delete img.dataset.bg;
      },
    });
    observer.observe();
  }, []);

  const htlListRender = hightlights.map((_, index) => {
    const _valueTmp = intl.messages[`home.resources.hlt.${index}.value`];
    const value = typeof _valueTmp === 'string' ? Number(_valueTmp) : 0;
    return (
      <div key={index} className={styles.highlight__item}>
        <div className={styles.highlight__item__wrapper}>
          <div className={styles.highlight__item__value}>
            <AutoIncNumber value={value} />
          </div>

          <div className={styles.highlight__item__unit}>
            <FormattedMessage
              id={`home.resources.hlt.${index}.unit`}
              defaultMessage={' '}
            />
          </div>
        </div>

        <div className={styles.highlight__item__content}>
          <FormattedMessage id={`home.resources.hlt.${index}.content`} />
        </div>
      </div>
    );
  });

  return (
    <div className={cls(styles.container, lazyloadBgClassName)}>
      <div className={styles.bg}></div>
      <div
        className={styles.content}
        data-bg="/overseacdn/homepage/static/images/home/resources/map.png"
      >
        <div className={styles.img__container}>
          <div className={styles.img__wrapper} data-title="resources">
            <LazyImg
              src="/overseacdn/homepage/static/images/home/resources/resource.png"
              alt="resources"
              overlayStyle={{ display: 'block' }}
            />
          </div>
        </div>

        <div className={styles.wrapper}>
          <h2 className={cls(styles.title, 'font')}>
            <FormattedMessage
              id="home.resources.title"
              values={{
                n: formatNewLine,
              }}
            />
          </h2>

          <div className={styles.highlight}>
            <div className={styles.highlight__wrapper}>{htlListRender}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resources;
