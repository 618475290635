import { Fragment } from 'react';
import Partners from './Partners';
import Resources from './Resources';
import Strengths from './Strengths';
import MarketingSolution from './MarketingSolution';
import Hero from './Hero';

const HomeComp: React.FC = () => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <Hero />
      <MarketingSolution />
      <Resources />
      <Strengths />
      <Partners />
    </div>
  );
};

export default HomeComp;
