import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import cls from 'classnames';
import lozad from 'lozad';

import useWindowScroll from '@/hooks/useWindowScroll';
import { formatList, formatNewLine } from '@/components/common/Intl';
import Container from '@/components/common/Container';
import { localeClassNameLabel } from '@/constants/locale';
import { STATIC_ASSETS_URL } from '@/constants/common';
import styles from './index.module.scss';

export const highlights = [
  'branding',
  'kol_marketing',
  'paid_advertising',
  'live_streaming',
];

const lazyloadBgClassName = 'lazyloadBg';
const scrollMarginTop = {
  'zh-CN': 132,
  'en-US': 140,
};

const idPrefix = 'services_highlight_';

const ServiceComp: React.FC = () => {
  const [activeNavKey, setActiveNavKey] = useState<string>(highlights[0]);
  const router = useRouter();
  const { locale } = router;

  useEffect(() => {
    //根据query跳转对应位置
    const { tab } = router.query;
    if (typeof tab === 'string' && highlights.includes(tab)) {
      handleChangeHlt(tab);
    }
  }, [router]);

  useEffect(() => {
    const observer = lozad(`.${lazyloadBgClassName}`, {
      load: function (img: HTMLImageElement) {
        if (
          img.dataset.bg !==
          `/overseacdn/homepage/static/images/services/branding_bg.png`
        ) {
          img.style.backgroundImage = `url(${STATIC_ASSETS_URL}${img.dataset.bg})`;
        }
        img.classList.remove(lazyloadBgClassName);
        delete img.dataset.bg;
      },
    });
    observer.observe();
    //懒加载背景标题
    let index = 1;
    const observerHighlightTitle = lozad(`.${styles.highlight__title}`, {
      load: function (h2: HTMLHRElement) {
        h2.classList.add(styles[`title__bg${index}`]);
        index++;
      },
    });
    observerHighlightTitle.observe();
  }, []);

  useEffect(() => {
    const bgDom: HTMLImageElement = document.querySelector(
      `.${styles[`highlight__bg0`]}`
    );
    if (locale === 'en-US') {
      bgDom.style.backgroundImage = `url(${STATIC_ASSETS_URL}/overseacdn/homepage/static/images/services/branding_bg_en.png)`;
    } else {
      bgDom.style.backgroundImage = `url(${STATIC_ASSETS_URL}/overseacdn/homepage/static/images/services/branding_bg.png)`;
    }
  }, [locale]);

  useWindowScroll(() => {
    let current = '';
    for (const highlight of highlights) {
      const id = idPrefix + highlight;
      const element = document.getElementById(id);
      if (element && element.getBoundingClientRect().top < 200) {
        current = id;
      }
    }
    setActiveNavKey(current ? current.replace(idPrefix, '') : highlights[0]);
  }, [activeNavKey]);

  useWindowScroll(() => {
    const element = document.getElementById(`${idPrefix}list`);

    if (element && element.getBoundingClientRect().top <= 70) {
      element.classList.add(styles.list__active);
    } else {
      element.classList.remove(styles.list__active);
    }
  }, [locale]);

  //跳转到对应的模块
  const handleChangeHlt = (highlight: string) => {
    const element = document.getElementById(`${idPrefix}${highlight}`);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const bgSplineRender = (
    <motion.div
      className={styles.list__item__spline}
      layoutId="highlightSpline"
    />
  );

  const highlightsRender = highlights.map((item, index) => {
    const isActive = activeNavKey === item;
    return (
      <li
        key={item}
        className={cls(
          styles.list__item,
          styles[`list__item__${localeClassNameLabel[locale]}`],
          { [styles.list__item__active]: isActive }
        )}
        onClick={handleChangeHlt.bind(null, item)}
      >
        <FormattedMessage
          id={`services.highlight.${index}.title`}
          values={{
            n: formatNewLine,
          }}
        />
        {isActive && bgSplineRender}
      </li>
    );
  });

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={cls(styles.title, 'font')}>
          <FormattedMessage id="services.title" />
        </h1>
      </header>
      <nav id={`${idPrefix}list`} className={styles.nav}>
        <ul
          className={cls(
            styles.list,
            styles[`list__${localeClassNameLabel[locale]}`]
          )}
        >
          {highlightsRender}
        </ul>
      </nav>
      <div className={styles.wrapper}>
        {highlights.map((item, index) => (
          <article
            key={item}
            id={`${idPrefix}${item}`}
            style={{
              height: 750,
              scrollMarginTop: scrollMarginTop[locale] + 1,
            }}
          >
            <Container
              key={item}
              style={{
                backgroundColor: index % 2 ? '#FFFFFF' : '#FBFBFC',
              }}
              className={cls(
                styles.highlight,
                lazyloadBgClassName,
                styles[`highlight__bg${index}`]
              )}
              data-bg={`/overseacdn/homepage/static/images/services/${item}_bg.png`}
            >
              <div
                className={styles.highlight__wrapper}
                style={{
                  flexDirection: index % 2 ? 'row-reverse' : 'row',
                }}
              >
                <div>
                  <h2
                    className={cls(styles.highlight__title, 'font')}
                    data-title={item}
                  >
                    <FormattedMessage
                      id={`services.highlight.${index}.title`}
                      values={{
                        n: (chunk) => chunk,
                      }}
                    />
                  </h2>
                  <p
                    className={cls(
                      styles.highlight__description,
                      styles[
                        `highlight__description__${localeClassNameLabel[locale]}__${index}`
                      ]
                    )}
                  >
                    <FormattedMessage
                      id={`services.highlight.${index}.description`}
                    />
                  </p>

                  <ul
                    className={cls(
                      styles.highlight__summary,
                      styles[
                        `highlight__summary__${localeClassNameLabel[locale]}__${index}`
                      ]
                    )}
                  >
                    <FormattedMessage
                      id={`services.highlight.${index}.summary`}
                      values={{
                        l: formatList,
                      }}
                    />
                  </ul>
                </div>

                <div className={styles.highlight__img}></div>
              </div>
            </Container>
          </article>
        ))}
      </div>
    </div>
  );
};

export default ServiceComp;
