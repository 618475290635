import { DependencyList, useEffect } from 'react';
import { simpleThrottle } from '@/utils';

const useWindowScroll = (callback: () => void, deps: DependencyList = []) => {
  useEffect(() => {
    const throttleCallback = simpleThrottle(callback, 0);
    throttleCallback();
    window.addEventListener('scroll', throttleCallback);
    return () => {
      window.removeEventListener('scroll', throttleCallback);
    };
  }, [...deps, callback]);
};

export default useWindowScroll;
