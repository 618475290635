import { useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { AnimatePresence, motion, useInView } from 'framer-motion';
import type { Variants } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import { formatHighlight, formatNewLine } from '@/components/common/Intl';
import Container from '@/components/common/Container';
import { useRouter } from 'next/router';

import styles from './Partners.module.scss';
import { STATIC_ASSETS_URL } from '@/constants/common';
import { localeClassNameLabel } from '@/constants/locale';
enum Directions {
  PRE = -1,
  CUR,
  NEXT,
}

const tabList: Record<'key' | 'label', string>[] = [
  {
    key: 'game',
    label: 'Game',
  },
  {
    key: 'ec-dtc',
    label: 'E-commerce & DTC',
  },
  {
    key: 'social',
    label: 'Social App',
  },
];

const variants: Variants = {
  enter: (direction: Directions) => {
    return {
      x: direction === Directions.NEXT ? 24 : -24,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: Directions) => {
    return {
      zIndex: 0,
      x: direction === Directions.PRE ? 24 : -24,
      opacity: 0,
    };
  },
};

const autoPlayInterval = 6000; /** ms */

const Partners: React.FC = () => {
  const [[page, direction], setPage] = useState<[number, number]>([0, 0]);
  const autoPlayTimer = useRef<number>();
  const containerRef = useRef<HTMLDivElement>(null);
  const containerIsInView = useInView(containerRef, { once: true });
  const router = useRouter();
  const { locale = 'en-US' } = router;

  useEffect(() => {
    if (containerIsInView) {
      const autoPlay = () => {
        autoPlayTimer.current && window.clearTimeout(autoPlayTimer.current);
        autoPlayTimer.current = window.setTimeout(() => {
          const nextPage = page + Directions.NEXT;
          handlePaginate(
            nextPage >= tabList.length ? 0 : nextPage,
            Directions.NEXT
          );
        }, autoPlayInterval);
      };

      autoPlay();
    }

    return () => {
      autoPlayTimer.current && window.clearTimeout(autoPlayTimer.current);
    };
  }, [page, containerIsInView]);

  const handlePaginate = (newPage: number, newDirection: Directions) => {
    setPage([newPage, newDirection]);
  };

  const bgSplineRender = (
    <motion.div className={styles.tabs__spline} layoutId="tabSpline" />
  );

  const tabsRender = tabList.map(({ label, key }, index) => {
    const isSelected = page === index;
    return (
      <li
        className={cls(styles.tabs__item, {
          [styles.tabs__item__active]: isSelected,
        })}
        key={key}
        onClick={() => {
          if (index > page) {
            handlePaginate(index, Directions.NEXT);
          } else if (index < page) {
            handlePaginate(index, Directions.PRE);
          }
        }}
      >
        <FormattedMessage
          id={`home.partners.tabs.${key}`}
          defaultMessage={label}
        />
        {isSelected && bgSplineRender}
      </li>
    );
  });
  return (
    <section ref={containerRef} className={styles.container}>
      <Container className={styles.wrapper}>
        <div
          className={cls(
            styles[`title__${localeClassNameLabel[locale]}`],
            'font'
          )}
          data-title="partners"
        >
          <FormattedMessage
            id="home.partners.title"
            defaultMessage="We are the <h>reliable business</h> partner <n></n> trusted by leading companies"
            values={{
              h: formatHighlight,
              n: formatNewLine,
            }}
          />
        </div>
        <div className={styles.subtitle}>
          <FormattedMessage
            id="home.partners.subtitle"
            defaultMessage="home.partners.subtitle"
          />
        </div>

        <ul className={styles.tabs__wrapper}>{tabsRender}</ul>

        <AnimatePresence exitBeforeEnter custom={direction}>
          <motion.div
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ duration: 0.3 }}
            className={styles.tabs__img}
          >
            <div
              style={{
                width: 1200,
                minHeight: 465,
                backgroundImage: containerIsInView
                  ? `url(${STATIC_ASSETS_URL}/overseacdn/homepage/static/images/home/partners/${tabList[page].key}.png)`
                  : '',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </motion.div>
        </AnimatePresence>
      </Container>
    </section>
  );
};

export default Partners;
