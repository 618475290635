import { useEffect } from 'react';
import type { NextPage } from 'next';
import * as Sentry from '@sentry/nextjs';
import HomeComp from '@/components/pages/home';
import { addGtagEvent } from '@/utils/addGtagEvent';
import { gaEventName } from '@/constants/googleAnalytics';

const IndexPage: NextPage = () => {
  useEffect(() => {
    // todo添加埋点
    async function getIpClient() {
      //请求单个可能会超时
      const ipUrl = [
        'https://api.ipify.org',
        'https://v4.ident.me/',
        'https://ifconfig.me/ip',
        'https://ipv4.icanhazip.com/',
      ];
      try {
        const res = await Promise.race(ipUrl.map((url) => fetch(url)));
        const ip = await res.text();
        return ip;
      } catch (error) {
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          Sentry.captureException(error);
        });
        return null;
      }
    }
    const postGa = async () => {
      const ip = await getIpClient();
      // UV上报
      addGtagEvent(gaEventName.UNIQUE_VISITOR, {}, true, 'index_uv');
      const t = window.performance.getEntriesByType(
        'navigation'
      )[0] as PerformanceNavigationTiming;

      t &&
        gtag('event', 'load_fullpage', {
          DOMContentLoaded: t.domInteractive - t.startTime,
          Loaded: t.loadEventEnd - t.startTime,
          ip,
        });
    };
    postGa();
  }, []);

  return <HomeComp />;
};

export default IndexPage;
