import cls from 'classnames';
import { useRouter } from 'next/router';
import { motion, useInView, Variants } from 'framer-motion';
import { FormattedMessage } from 'react-intl';

import { formatHighlight, formatNewLine } from '@/components/common/Intl';
import Container from '@/components/common/Container';
import LazyImg from '@/components/common/LazyImg';

import { highlights } from '../services';
import styles from './MarketingSolution.module.scss';
import { localeClassNameLabel } from '@/constants/locale';
import { useRef } from 'react';

const solutionList = ['branding', 'kol', 'ads', 'live'];

const listWidths = {
  'zh-CN': [242, 220, 242, 200],
  'en-US': [241, 252, 264, 273],
};

const listVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const itemVariants: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const MarketingSolution: React.FC = () => {
  const router = useRouter();
  const { locale = 'en-US' } = router;
  const titleRef = useRef<HTMLHeadingElement>(null);
  const titleIsInView = useInView(titleRef);

  return (
    <section className={styles.container}>
      <Container className={styles.wrapper}>
        <div className="lazy-img-show">
          <h2
            ref={titleRef}
            className={cls(
              styles.title,
              'font',
              styles[`title__${localeClassNameLabel[locale]}`]
            )}
          >
            <FormattedMessage
              id="home.solutions.title"
              values={{
                h: formatHighlight,
                n: formatNewLine,
              }}
            />
          </h2>
        </div>
        <motion.ul
          className={styles.list}
          variants={listVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {solutionList.map((item, index) => (
            <motion.li
              style={{ flexBasis: listWidths[locale][index] }}
              key={item}
              className={cls(styles.item, {
                [styles.item__zh]: locale === 'zh-CN',
                [styles.item__en]: locale === 'en-US',
              })}
              variants={itemVariants}
              onClick={() => {
                router.push(`/services?tab=${highlights[index]}`);
              }}
            >
              <LazyImg
                src={`/overseacdn/homepage/static/images/home/solutions/${item}.svg`}
                width={48}
                height={48}
                alt={item}
              />
              <h3 className={styles.item__title}>
                <FormattedMessage id={`home.solutions.${item}.title`} />
              </h3>
              <p className={styles.item__content}>
                <FormattedMessage id={`home.solutions.${item}.content`} />
              </p>
            </motion.li>
          ))}
        </motion.ul>
      </Container>
    </section>
  );
};

export default MarketingSolution;
