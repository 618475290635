import Video from './Video';
import styles from './Hero.module.scss';
import Container from '@/components/common/Container';

const Hero: React.FC = () => {
  return (
    <div className={styles.container}>
      <Container>
        <Video />
      </Container>
    </div>
  );
};

export default Hero;
